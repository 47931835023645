<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import { required } from "vuelidate/lib/validators";
import * as moment from "moment";
import Multiselect from "vue-multiselect";
import OtpInput from "@bachdgvn/vue-otp-input";
import Common from "../../../helpers/Common";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";

import {
  getPayoutList,
  createPayouts,
  approvePayouts,
  virtualAccountList,
  generateOtp,
  getBeneficiary,
} from "@/state/helpers";
import {
  FETCH_PAYOUT_LIST,
  EXPORT_PAYOUTS_INTO_EXCEL,
  FETCH_PAYOUT_LIST_MASTER_MERCHANT,
  APPROVE_PAYOUTS_VIA_EMAIL,
  VERIFY_PAYOUTS_VIA_EMAIL,
  FETCH_VIRTUAL_ACCOUNTS,
  GENERATE_OTP_PAYOUT,
  GENERATE_OTP_PAYOUT_VIA_EMAIL,
  GENERATE_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY,
  RESEND_OTP_PAYOUT_VIA_EMAIL,
  RESEND_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY,
  FETCH_BENEFICIARY_TABLE,
  FETCH_MERCHANT_ENTITY_DETAILS,
  PAYOUT_FILE_UPLOAD,
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_MERCHANTS_LIST,
  UPDATE_PAYOUT_STATUS,
} from "@/state/actions.type";
import FilterBtn from "../../../components/filter-btn.vue";
import PayoutForm from './payout-form.vue';
import DownloadBtn from '../../../components/download-btn.vue';

export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    Multiselect,
    OtpInput,
    FilterBtn,
    PayoutForm,
    DownloadBtn,
    DatePicker
  },

  data() {
    return {
      split_payouts: true,
      selectedChannel: "",
      filePath: "",
      fileUploadSuccess: false,
      currentPageTitle: "statement",
      advancedFilterClosed: true,
      selectedTransactionRefs: [],
      selectAvailableInPage: false,
      selectAllTransactions: false,
      currentPage: 1,
      mor_filter: "",
      submitted: false,
      showmodal: false,
      showApprove: false,
      showUploadmodal: false,
      showVerify: false,
      enterMobile: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      merchants: [],
      verifyAction: "",
      isEdit: false,
      sorting_data: {
        "sort_by_key": "",
        "order": ""
      },
      fields: [
        {
          key: "selectpayout",
          label: "",
          tooltip: "views.payouts.table.tooltip.select",
          sortable: false,
          thClass: "",
          tdClass: "",
        },
        {
          key: "status",
          label: "views.payouts.table.fields.status",
          tooltip: "views.payouts.table.tooltip.status",
          sortable: true,
        },
        {
          key: "merchant_txn_ref",
          label: "views.payouts.table.fields.merchant_transaction_reference",
          tooltip:
              "views.payouts.table.tooltip.merchant_transaction_reference",
          sortable: true,
        },
        {
          key: "account_name",
          label: "views.payouts.table.fields.account_name_1",
          tooltip: "Beneficiary Account name",
          sortable: true,
        },
        {
          key: "bank_name",
          label: "views.payouts.table.fields.bank_name",
          tooltip: "views.payouts.table.fields.bank_name",
          sortable: true,
        },
        {
          key: "to_account_no",
          label: "views.payouts.table.fields.beneficiary_account_number",
          tooltip:
              "views.payouts.table.tooltip.beneficiary_account_number",
          sortable: true,
        },
        {
          key: "amount",
          label: "views.payouts.table.fields.amount",
          tooltip: "views.payouts.table.tooltip.amount",
          sortable: true,
        },
        {
          key: "email_sent",
          label: "views.payouts.table.fields.sent_email",
          tooltip: "views.payouts.table.fields.sent_email",
          sortable: true,
        },
        {
          key: "sms_sent",
          label: "views.payouts.table.fields.sent_sms",
          tooltip: "views.payouts.table.fields.sent_sms",
          sortable: true,
        },
        {
          key: "due_date",
          label: "views.payouts.table.fields.scheduled_time",
          tooltip: "views.payouts.table.tooltip.scheduled_time",
          sortable: true,
        },
        {
          key: "vaaccount_no",
          label: "views.payouts.table.fields.virtual_account_number",
          tooltip:
            "views.payouts.table.tooltip.virtual_account_number",
          sortable: true,
        },
        {
          key: "verified_status",
          label: "views.payouts.table.fields.verify_status",
          sortable: true,
          thClass: "",
          tdClass: "",
          tooltip:
            "views.payouts.table.tooltip.verify_status",
        },
        {
          key: "verified_by",
          label: "views.payouts.table.fields.verified_by",
          tooltip:
            "views.payouts.table.tooltip.verified_by",
          sortable: true,
          thClass: "",
          tdClass: "",
        },
        {
          key: "approved_status",
          label: "views.payouts.table.fields.approval_status",
          sortable: true,
          tooltip:
            "views.payouts.table.tooltip.approval_status",
        },
        {
          key: "approved_by",
          label: "views.payouts.table.fields.approved_by",
          tooltip:
            "views.payouts.table.tooltip.approved_by",
          sortable: true,
        },
        {
          key: "approved_time",
          label: "views.payouts.table.fields.approved_time",
          tooltip: "views.payouts.table.tooltip.approved_time",
          sortable: true,
        },
        {
          key: "status_channel_reason",
          label: "views.payouts.table.fields.tx_status",
          tooltip: "views.payouts.table.tooltip.tx_status",
          sortable: true,
        },
        {
          key: "txn_ref",
          label: "views.payouts.table.fields.transaction_reference",
          tooltip:
            "views.payouts.table.tooltip.transaction_reference",
          sortable: true,
        },
        {
          key: "action",
          label: "views.payments.routing.action",
          tooltip: "views.payments.routing.action",
          sortable: false,
          stickyColumn: true,
        },
      ],
      filters: {
        approved_status: "",
        status: [],
        verified_status: "",
      },
      from: "",
      to: "",
      payoutFilterStatus: [
        "Success",
        "Created",
        "Failed",
        "Initiated",
        "Pending",
        "Cancelled",
        "Rejected",
      ],
      payoutApprovalFilterStatus: ["Approved", "Pending", "Rejected"],
      payoutVerifyFilterStatus: ["Verified", "Pending", "Rejected"],
      // account_name: "",
      // name: "",
      payoutApprove: {
        otp: "",
        saveOtp: false
      },

      payoutVerify: {
        otp: "",
        saveOtp: false
      },

      payoutMobile: {
        mobileNo: "",
      },
      editItem: null
    };
  },

  created() {
    if (this.isMasterMerchant) {
      this.$store
        .dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
          pageIndex: 1,
          pagesize: 10,
          filters: { source: "default" },
        })
        .then((response) => {
          if (response.content.length > 0) {
            this.refreshRecord();
          }
        });
    } else {
      this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`);
      this.refreshRecord();

      this.$store.dispatch(`virtualaccount/${FETCH_VIRTUAL_ACCOUNTS}`);
      this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, {});
      this.$store
        .dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
          country: [],
          paymentMethod: "",
          paymentType: "PAYOUT",
        })
        .then(() => {
          if (
            this.paymentChannelCredsList &&
            this.paymentChannelCredsList.length == 1
          ) {
            this.selectedChannel = this.paymentChannelCredsList[0];
          }
        });
    }
  },

  computed: {
    paymentChannelCredsList() {
      let channels = this.$store.state.paymentChannels.channels;
      let channelList = [];
      for (let psp in channels) {
        if (channels[psp].onboarding_status.status == "Completed") {
          channelList.push(psp);
        }
      }
      return channelList;
      // return this.$store.state.paymentChannels.channels;
    },

    minimunDate() {
      return moment().format("YYYY-MM-DDThh:mm");
    },

    title() {
      return this.$t("menuitems.payouts.list.payouts");
    },

    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.payouts"),
          active: true,
        },
        {
          text: this.$t("menuitems.payouts.list.vendor_bank"),
          active: true,
        },
      ];
    },

    payoutsDataFromStore() {
      return this.$store.state.payouts.content;
    },

    payoutTotalElementsFromStore() {
      return this.$store.state.payouts.total_elements;
    },

    rows() {
      return this.$store.state.payouts.total_elements;
    },

    isFetchingTransactions() {
      return this.$store.state.payouts.isFetchingData;
    },

    userPermissions() {
      return this.$store.getters["auth/getUserPermissionsList"];
    },

    isVerifyPayoutsEnabledData() {
      return this.$store.getters["merchant/getMerchantEntityDetails"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  validations: {
    payoutApprove: {
      otp: { required },
    },
    payoutVerify: {
      otp: { required },
    },
    payoutMobile: {
      mobileNo: { required },
    },
  },

  watch: {
    currentPage() {
      this.refreshRecord().then(() => {

        this.selectAvailableInPage = true;

        if(!this.selectAllTransactions) {
          this.checkAllInPageAreSelected();
        } else {
          this.pushPayoutTransactionsInSeleted();
        }
      });
    },
    isVerifyPayoutsEnabledData() {
      let fieldsToToggle = ['verified_status'];
      let thTdClass = "";
      if (this.isVerifyPayoutsEnabledData.isVerifyPayoutsEnabled) {
        if (!this.isMasterMerchant) {
          fieldsToToggle = [...fieldsToToggle, 'verified_by'];
        }
      } else {
        thTdClass = "d-none";
        if (!this.isMasterMerchant) {
          fieldsToToggle = [...fieldsToToggle, 'verified_by'];
        }
      }
      this.fields = this.fields.map((field) => {
        if(fieldsToToggle.includes(field.key)) {
          field.thClass = thTdClass;
          field.tdClass = thTdClass;
        }
        return field;
      });
    },
    showmodal(value) {
      if(!value) {
        this.editItem = null;
        setTimeout(() => {
          this.refreshRecord();
        }, 500);
      }
    },
    'from': function(newFromDate) {
      if (newFromDate !== null && newFromDate !== "2006-01-02T00:00:00.000Z" && newFromDate !== "") {
        const toDate = new Date(newFromDate);
        console.log(toDate)
        toDate.setHours(23, 59, 59); // Set time to the end of the day
        this.to = toDate;
      }
      else{
        this.to = "";
      }
    },
  },

  mounted() {
    this.$root.$on('hide-payout-modal', () => {
      this.isEdit = false;
      this.showmodal = false;
    })
  },

  methods: {
    ...getPayoutList,
    ...createPayouts,
    ...approvePayouts,
    ...virtualAccountList,
    ...generateOtp,
    ...getBeneficiary,

    openUploadModal() {
      this.showUploadmodal = true;
    },

    hideUploadModal() {
      this.showUploadmodal = false;
    },

    approveModal(action) {
      this.$store
        .dispatch(`payouts/${GENERATE_OTP_PAYOUT_VIA_EMAIL}`)
        .then((response) => {
          this.approveAction = action;
          if(response.approve_otp_bypass_allowed) {
            this.approve(true);
          } else {
            this.submitted = false;
            this.showApprove = true;
  
            this.resetPayoutApprove();
            this.resetPayoutMobile();
          }
        })
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
        });
    },

    verifyModal(action) {
      this.$store
        .dispatch(`payouts/${GENERATE_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY}`)
        .then((response) => {
          this.verifyAction = action;
          if(response.verify_otp_bypass_allowed) {
            this.verify(true);
          } else {
            this.submitted = false;
            this.showVerify = true;
  
            this.resetPayoutVerify();
            this.resetPayoutMobile();
          }
        })
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
        });
    },

    hideMobile() {
      this.submitted = false;
      this.enterMobile = false;
      this.resetPayoutMobile();
    },

    hideApprove() {
      this.submitted = false;
      this.showApprove = false;
      this.resetPayoutApprove();
    },

    hideVerify() {
      this.submitted = false;
      this.showVerify = false;
      this.resetPayoutVerify();
    },

    getNotVerifiedData() {
      this.selectedTransactionRefs = [];
      this.selectAvailableInPage = false;
      this.currentPageTitle = "not-verified";
      this.filters = {
        approved_status: "Pending",
        status: ["Created"],
        verified_status: "Pending",
      };
      this.currentPage = 1;
      this.perPage = 10;
      this.refreshRecord();
      if (this.userPermissions.includes("verify-payouts")) {
        this.fields[0].thClass = "";
        this.fields[0].tdClass = "";
      } else {
        this.fields[0].thClass = "d-none";
        this.fields[0].tdClass = "d-none";
      }
    },

    getNotApprovedData() {
      this.selectedTransactionRefs = [];
      this.selectAvailableInPage = false;
      this.currentPageTitle = "not-approved";
      this.filters = {
        approved_status: "Pending",
        status: ["Created"],
        verified_status: "Verified",
      };
      this.currentPage = 1;
      this.perPage = 10;
      this.refreshRecord();
      if (this.userPermissions.includes("approve-payouts")) {
        this.fields[0].thClass = "";
        this.fields[0].tdClass = "";
      } else {
        this.fields[0].thClass = "d-none";
        this.fields[0].tdClass = "d-none";
      }
    },

    getStatementData() {
      this.selectedTransactionRefs = [];
      this.selectAvailableInPage = false;
      this.currentPageTitle = "statement";
      this.filters = {
        approved_status: "",
        status: [],
        verified_status: "",
      };
      this.currentPage = 1;
      this.perPage = 10;
      this.refreshRecord();
    },

    openModal() {
      this.showmodal = true;
    },

    getFormattedDate(isoDate) {
      if (isoDate == "0001-01-01T00:00:00Z" || isoDate == "") {
        return "-";
      } else {
        return moment(isoDate).format("MMMM Do YYYY, h:mm:ss a");
      }
    },

    checkAllInPageAreSelected() {
      this.selectAvailableInPage = true;
      for(let element of this.payoutsDataFromStore) {
        if(!this.selectedTransactionRefs.includes(element.txn_ref)) {
          this.selectAvailableInPage = false;
          break;
        }
      }
    },

    toggleSelectPayout(txnRef) {
      this.selectAllTransactions = false;

      if(this.selectedTransactionRefs.includes(txnRef)) {
        this.selectedTransactionRefs = this.selectedTransactionRefs.filter(element => element !== txnRef);
      } else {
        this.selectedTransactionRefs.push(txnRef);
      }

      this.checkAllInPageAreSelected();

      if(this.selectedTransactionRefs.length === this.payoutTotalElementsFromStore) {
        this.selectAllTransactions = true;
      } else {
        this.selectAllTransactions = false;
      }
    },

    pushPayoutTransactionsInSeleted() {
      this.payoutsDataFromStore.map((element) => {
        if(!this.selectedTransactionRefs.includes(element.txn_ref)) {
          this.selectedTransactionRefs.push(element.txn_ref)
        }
      });
    },

    selectAllInPage(value) {
      this.selectAllTransactions = false;

      if(value) {
        this.pushPayoutTransactionsInSeleted();
      } else {
        const unselectedItems = this.payoutsDataFromStore.map((element) => element.txn_ref);
        this.selectedTransactionRefs = this.selectedTransactionRefs.filter(element => !unselectedItems.includes(element));
      }

      if(this.selectedTransactionRefs.length === this.payoutTotalElementsFromStore) {
        this.selectAllTransactions = true;
      } else {
        this.selectAllTransactions = false;
      }
    },

    selectAllTxn() {
      this.selectAllTransactions = true;
      this.selectAvailableInPage = true;

      this.pushPayoutTransactionsInSeleted();
    },

    refreshRecord() {
      if (this.mor_filter == "") {
        delete this.filters["txn_ref"];
      } else {
        this.filters.txn_ref = this.mor_filter;
      }

      let endTime = new Date().toISOString()
      if(this.to) {
        endTime = this.to;
      }

      if(!this.from) {
        this.from = new Date('2006-01-02').toISOString()
      }

      const postData = {
        from: this.from,
        to: endTime,
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        sorting_data: this.sorting_data
      };

      let response;
      if (this.isMasterMerchant) {
        postData.filters.merchants = this.merchants.map(
          (merchant) => merchant.email_id
        );
        response = this.$store.dispatch(
          `payouts/${FETCH_PAYOUT_LIST_MASTER_MERCHANT}`,
          postData
        );
      } else {
        response = this.$store.dispatch(`payouts/${FETCH_PAYOUT_LIST}`, postData);
      }

      this.tableFieldsHideAndShow();
      return response;
    },

    tableFieldsHideAndShow() {
      let selectPayoutClass = "";
      let actionClass = "";
      if(this.currentPageTitle === "statement") {
        selectPayoutClass = "d-none";
      } else {
        actionClass = "d-none";
      }
      if(this.isMasterMerchant) {
        actionClass = "d-none";
      }
      this.fields = this.fields.map((field) => {
        if(field.key === 'selectpayout') {
          field.thClass = selectPayoutClass;
          field.tdClass = selectPayoutClass;
        }
        if(field.key === 'action') {
          field.thClass = actionClass;
          field.tdClass = actionClass;
        }
        return field;
      });
    },

    VerifyPayments() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.payoutVerify.$invalid || this.selectedTransactionRefs.length === 0) {
        return;
      }

      this.verify();

    },
    verify(otpSaved = false) {
      let payload = {
        approveData: {
          otp: this.payoutVerify.otp,
          txn_refs: this.selectedTransactionRefs,
          action: this.verifyAction,
          save_otp: this.payoutVerify.saveOtp
        },
      };

      if(otpSaved) {
          payload.approveData.otp = "";
          payload.approveData.save_otp = false;
        }

      this.showVerify = false;
      this.submitted = false;

      this.$store
        .dispatch(`payouts/${VERIFY_PAYOUTS_VIA_EMAIL}`, payload)
        .then(() => this.refreshRecord())
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
          setTimeout(() => {
            this.resetPayoutVerify();
            this.resetPayoutMobile();
          }, 3000);
          console.log(error);
        })
        .finally(() => {
          this.selectedTransactionRefs = [];
          this.selectAvailableInPage = false;
        });
    },

    ApprovePayments() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.payoutApprove.$invalid || this.selectedTransactionRefs.length === 0) {
        return;
      }
      this.approve();
    },
    approve(otpSaved = false) {
      let payload = {
        approveData: {
          otp: this.payoutApprove.otp,
          txn_refs: this.selectedTransactionRefs,
          action: this.approveAction,
          save_otp: this.payoutApprove.saveOtp
        },
      };

      if(otpSaved) {
        payload.approveData.otp = "";
        payload.approveData.save_otp = false;
      }

      console.log("approveData");
      this.showApprove = false;
      this.submitted = false;

      this.$store
        .dispatch(`payouts/${APPROVE_PAYOUTS_VIA_EMAIL}`, payload)
        .then(() => this.refreshRecord())
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
          setTimeout(() => {
            this.resetPayoutApprove();
            this.resetPayoutMobile();
          }, 3000);
          console.log(error);
        })
        .finally(() => {
          this.selectedTransactionRefs = [];
          this.selectAvailableInPage = false;
        });
    },

    GenerateOtp() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.payoutMobile.$invalid) {
        return;
      }
      this.$store.dispatch(
        `payouts/${GENERATE_OTP_PAYOUT}`,
        this.payoutMobile.mobileNo
      );

      this.submitted = false;
      this.enterMobile = false;
      this.resetPayoutApprove();
      this.resetPayoutVerify();
      this.resetPayoutMobile().catch((error) => {
        this.showmodal = false;
        setTimeout(() => {
          this.resetPayoutCreation();
          this.showmodal = false;
        }, 3000);
        console.log(error);
      });
    },

    resendOTP() {
      this.$store
        .dispatch(`payouts/${RESEND_OTP_PAYOUT_VIA_EMAIL}`)
        .then((result) => {
          console.log("Resend OTP successfully", result);
        })
        .catch((error) => {
          console.log("Failed to resend OTP", error);
        });
    },

    resendOTPForVerify() {
      this.$store
        .dispatch(`payouts/${RESEND_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY}`)
        .then((result) => {
          console.log("Resend OTP successfully", result);
        })
        .catch((error) => {
          console.log("Failed to resend OTP", error);
        });
    },

    fileUpload(fieldName, fileList) {
      let isValid = Common.excelFileValdation(fileList[0]);
      if (isValid) {
        this.filePath = fileList;
        this.fileUploadSuccess = true;
      } else {
        this.$refs.fileupload.value = "";
        Swal.fire(
          "error",
          `Maximum 20 MB file is allowed and xlsx files are allowed`
        );
      }
    },

    fileUploads() {
      if(this.selectedChannel === 'PAYNAMICS_PAYOUT') {
        return;
      }
      const formData = new FormData();
      if (!this.filePath.length) return;
      formData.append("file", this.filePath[0]);
      formData.append("key", this.$store.state.auth.currentUser.iamportKey);
      formData.append("channel_key", this.selectedChannel);
      if(this.selectedChannel == 'GBPRIMEPAY_PAYOUT') {
        formData.append("split_payouts", this.split_payouts);
      }
      this.$store
        .dispatch(`payouts/${PAYOUT_FILE_UPLOAD}`, {
          fileData: formData,
        })
        .then(() => {
          this.fileUploadSuccess = false;
          this.selectedChannel = "";
          this.$refs.fileupload.value = null;
          this.showUploadmodal = false;
          this.hideUploadModal();
          setTimeout(() => {
            this.refreshRecord();
          }, 500);
          this.$notify({
            type: "success",
            text: this.$t("common.file_upload_success"),
            closeOnClick: true,
          });
        })
        .catch((error) => {
          // error
          this.hideUploadModal();
          console.log("error", error);
          this.$notify({
            type: "error",
            text: JSON.parse(error.message).message,
            closeOnClick: true,
          });
        });
    },

    handleOtpChange(value) {
      this.payoutApprove.otp = value;
    },

    handlePayoutOtpChange(value) {
      this.payoutVerify.otp = value;
    },

    filterApplied() {
      return Boolean(
        this.filters.approved_status ||
          this.filters.verified_status ||
          (this.filters.status && this.filters.status.length)
      );
    },

    deletePayout(txn_ref) {
      this.$store
        .dispatch(`payouts/${UPDATE_PAYOUT_STATUS}`, txn_ref)
        .then(() => {
          this.refreshRecord();
        });
    },

    editPayout(item) {
      this.editItem = item;
      this.isEdit = true;
      this.showmodal = true;
    },

    resetPayoutApprove() {
      this.payoutApprove = {
        otp: "",
        saveOtp: false
      };
    },

    resetPayoutVerify() {
      this.payoutVerify = {
        otp: "",
        saveOtp: false
      };
    },

    resetAdvanceFilters() {
      this.advancedFilterClosed = !this.advancedFilterClosed;
      this.filters = {
        approved_status: "",
        status: [],
        verified_status: "",
      };
      if (this.advancedFilterClosed) {
        this.refreshRecord();
      }
      // this.$store.dispatch(`transactions/${FETCH_TRANSACTIONS}`, {pageIndex: 1, pagesize: 10, filters: this.filters})
    },

    sortTransactions(item) {

    this.sorting_data.sort_by_key = item.sortBy;

      if(item.sortDesc) {
        this.sorting_data.order = "DESC";
      } else {
        this.sorting_data.order = "ASC";
      }
      this.refreshRecord();
    },

    resetPayoutMobile() {
      this.payoutMobile = {
        mobileNo: "",
      };
    },
    exportTransactionIntoExcel() {
      let endTime = new Date().toISOString()
      if(this.to) {
        endTime = this.to;
      }
      if(!this.from) {
        this.from = new Date('2006-01-02').toISOString()
      }

      const postData = {
        from: this.from,
        to: endTime,
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        sorting_data: this.sorting_data
      };
      if (this.isMasterMerchant) {
        postData.merchant_keys = this.merchants.map(
          (merchant) => merchant.key
        );
      }
      this.$store.dispatch(`payouts/${EXPORT_PAYOUTS_INTO_EXCEL}`, postData);
    },

    disabledAfterEndDate(date) {
      if(this.to) {
        return date > new Date(this.to) || date > new Date();
      }
      return date > new Date();
    },

    disabledBeforeStartDate(date) {
      if(this.from) {
        return date < new Date(this.from) || date > new Date();
      }
      return date > new Date();
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row justify-content-end mr-2">
      <multiselect
        v-if="isMasterMerchant"
        v-model="merchants"
        :options="merchantListDataFromStore"
        @input="refreshRecord"
        placeholder="Search Merchants"
        track-by="email_id"
        label="email_id"
        class="sm-multiselect col-md-3"
        :searchable="true"
        :multiple="true"
        :taggable="true"
      ></multiselect>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab @click="getStatementData()" title-link-class="p-3">
                <template v-slot:title>
                  {{ $t("views.payouts.transaction") }}
                </template>
                <!-- <button
                  href="javascript:void(0);"
                  class="btn btn-secondary mt-3 btn"
                  @click="openModal()"
                >
                  {{ $t("views.payouts.create-payouts") }}
                </button> -->
                <div
                  class="row my-4 d-flex justify-content-end"
                  v-if="!isMasterMerchant"
                >
                  <div class="mr-3">
                    <button
                      class="btn btn-primary btn-sm mr-2"
                      @click="openUploadModal()"
                    >
                      {{ $t("views.payouts.upload_payout") }}
                    </button>
                    <button
                      v-if="userPermissions.includes('create-payouts')"
                      class="btn btn-primary btn-sm"
                      @click="openModal()"
                    >
                      {{ $t("views.payouts.create-payouts") }}
                    </button>
                  </div>
                </div>

                <div v-if="currentPageTitle == 'statement'">
                  <div class="row my-4 d-flex justify-content-between">
                    <div class="col-sm-12 col-md-6 d-flex align-items-baseline">
                      <div @click="resetAdvanceFilters()">
                        <FilterBtn
                          :isActive="filterApplied()"
                          v-b-toggle.payouts-filter-collapse
                        />
                      </div>
                      <div
                        id="tickets-table_length"
                        class="dataTables_length ml-3"
                      >
                        <label class="d-flex align-items-baseline">
                          <span class="text-nowrap">{{
                            $t("common.show")
                          }}</span
                          >&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="refreshRecord"
                          ></b-form-select
                          >&nbsp;<span class="text-nowrap">{{
                            $t("common.entries")
                          }}</span>
                        </label>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                      <div style="width: 70% !important">
                        <b-input-group>
                          <b-form-input
                            type="search"
                            v-model="mor_filter"
                            @keyup="refreshRecord"
                            @keyup.enter="refreshRecord"
                            class="form-control-sm"
                            placeholder="Search for references or customer details"
                          ></b-form-input>
                          <b-input-group-append>
                            <button
                              class="btn btn-primary btn-block btn-sm"
                              @click="refreshRecord"
                            >
                              {{ $t("button.search") }}
                            </button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <div class="down-btn" @click="exportTransactionIntoExcel()">
                        <downloadBtn />
                      </div>
                    </div>
                    <!-- Custom Search End-->
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-12 col-md-12">
                      <b-collapse id="payouts-filter-collapse">
                        <b-card :title="$t('common.filters')">
                          <div class="row">
                            <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                              <label for="startdate" style="display: block;">{{ $t('common.start_date') }}</label>
                              <date-picker
                                type="datetime"
                                :placeholder="$t('common.start_date')"
                                format="DD-MM-YYYY HH:mm"
                                v-model="from"
                                name="startdate"
                                class="custom-datepicker w-100"
                                prefix-class="xmx"
                                :disabled-date="disabledAfterEndDate"
                              ></date-picker>
                            </div>
                            <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                              <label for="enddate" style="display: block;">{{ $t('common.end_date') }}</label>
                              <date-picker
                                type="datetime"
                                :placeholder="$t('common.end_date')"
                                v-model="to"
                                format="DD-MM-YYYY HH:mm"
                                name="enddate"
                                class="custom-datepicker w-100"
                                prefix-class="xmx"
                                :disabled-date="disabledBeforeStartDate"
                              ></date-picker>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-3">
                              <multiselect
                                v-model="filters.status"
                                :options="payoutFilterStatus"
                                placeholder="Select Payout Status"
                                class="sm-multiselect"
                                :searchable="false"
                                :multiple="true"
                                :taggable="true"
                              ></multiselect>
                            </div>
                            <div class="col-sm-12 col-md-3">
                              <multiselect
                                v-model="filters.approved_status"
                                :options="payoutApprovalFilterStatus"
                                :placeholder="
                                  $t('views.payouts.select_approval_status')
                                "
                                class="sm-multiselect"
                                :searchable="false"
                                :multiple="false"
                                :taggable="true"
                              ></multiselect>
                            </div>
                            <div
                              class="col-sm-12 col-md-3"
                              v-if="
                                isVerifyPayoutsEnabledData.isVerifyPayoutsEnabled ||
                                isMasterMerchant
                              "
                            >
                              <multiselect
                                v-model="filters.verified_status"
                                :options="payoutVerifyFilterStatus"
                                :placeholder="
                                  $t('views.payouts.select_verify_status')
                                "
                                class="sm-multiselect"
                                :searchable="false"
                                :multiple="false"
                                :taggable="true"
                              ></multiselect>
                            </div>
                            <div class="col-sm-12 col-md-6 col-xl-3 ml-auto">
                              <div class="row">
                                <div class="col-sm-12 col-md-6">
                                  <b-button
                                    variant="primary"
                                    size="sm"
                                    style="height: 35px"
                                    class="btn btn-block"
                                    @click="refreshRecord"
                                    >{{ $t("button.apply") }}</b-button
                                  >
                                </div>
                                <div class="col-sm-12 col-md-6">
                                  <b-button
                                    variant="light"
                                    size="sm"
                                    style="height: 35px"
                                    class="btn btn-block"
                                    @click="resetAdvanceFilters()"
                                    v-b-toggle.payouts-filter-collapse
                                    >{{ $t("button.clear") }}</b-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card>
                      </b-collapse>
                    </div>
                  </div>

                  <div class="">
                    <b-table
                      class="table-centered text-center table-custom"
                      :items="payoutsDataFromStore"
                      :fields="fields"
                      responsive="lg"
                      @sort-changed="sortTransactions"
                      :bordered="false"
                      sticky-header="500px"
                      show-empty
                      :empty-text="$t('common.no-records')"
                      busy.sync="true"
                      hover
                    >
                      <template v-slot:cell(amount)="row">
                        <div>
                          <span class="align-middle">{{
                            row.value.toLocaleString("en-US", {
                              style: "currency",
                              currency: row.item.currency,
                            })
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(email_notification_sent)="row">
                        <img src="@/assets/images/check-circle.svg" v-if="row.value">
                        <img src="@/assets/images/x-circle.svg" v-else>
                      </template>

                      <template v-slot:cell(sms_notification_sent)="row">
                        <img src="@/assets/images/check-circle.svg" v-if="row.value">
                        <img src="@/assets/images/x-circle.svg" v-else>
                      </template>

                      <template v-slot:cell(due_date)="row">
                        <div>
                          <span class="align-middle">{{
                            getFormattedDate(row.value)
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(approved_time)="row">
                        <div>
                          <span class="align-middle">{{
                            getFormattedDate(row.value)
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(approved_status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Approved',
                            'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                            'badge-soft-warning': row.value === 'Pending',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(email_sent)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Sent',
                            'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                            'badge-soft-warning': row.value === 'Waiting',
                            'badge-soft-secondary':
                              row.value === '' ||
                              row.value === 'N/A',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(sms_sent)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Sent',
                            'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                            'badge-soft-warning': row.value === 'Waiting',
                            'badge-soft-secondary':
                              row.value === '' ||
                              row.value === 'N/A',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(verified_status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Verified',
                            'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                            'badge-soft-warning': row.value === 'Pending',
                            'badge-soft-secondary':
                              row.value === '' ||
                              row.value === 'Not Applicable',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>
                      <template v-slot:cell(status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': `${row.value}` === 'Success',
                            'badge-soft-info': `${row.value}` === 'Created',
                            'badge-soft-warning':
                              `${row.value}` === 'Initiated' ||
                              `${row.value}` === 'Pending',
                            'badge-soft-secondary':
                              `${row.value}` === 'Pending',
                            'badge-soft-danger':
                              `${row.value}` === 'Failed' ||
                              `${row.value}` === 'Cancelled' ||
                              `${row.value}` === 'Rejected',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(action)="{ item }">
                        <b-btn
                          variant="light"
                          size="sm"
                          class="mr-2"
                          v-if="
                            item.status === 'Created' &&
                            item.approved_status !== 'Approved' &&
                            item.verified_status !== 'Verified'
                          "
                          @click="editPayout(item)"
                          >Edit</b-btn
                        >
                        <b-btn
                          variant="danger"
                          size="sm"
                          v-if="
                            item.status === 'Created' &&
                            item.approved_status !== 'Approved' &&
                            item.verified_status !== 'Verified'
                          "
                          @click="deletePayout(item.txn_ref)"
                          >Cancel</b-btn
                        >
                      </template>

                      <template v-slot:head()="data">
                        <span
                          v-b-tooltip.hover.top
                          :title="$t(data.field.tooltip)"
                        >
                          {{ $t(data.label) }}
                        </span>
                      </template>
                    </b-table>
                    <Spinner v-if="isFetchingTransactions === true"></Spinner>
                  </div>

                  <div class="row">
                    <div>
                      <div
                        class="right-bar"
                        :class="{
                          active: showUploadmodal,
                        }"
                      >
                        <img
                          src="@/assets/images/update_config_bottom.png"
                          class="right-bottom-bg-image"
                          alt
                        />
                        <div data-simplebar class="h-100">
                          <div class="rightbar-title pb-3">
                            <div class="right-top-section">
                              <img
                                src="@/assets/images/update_config_page.png"
                                class="cursor-pointer right-top-bg-image"
                                alt
                              />
                              <div class="card-body-custom">
                                <i
                                  class="mdi mdi-close noti-icon float-right cursor-pointer"
                                  style="
                                    position: relative;
                                    z-index: 1;
                                    right: 15px;
                                    top: 15px;
                                    font-size: 20px;
                                  "
                                  @click="hideUploadModal"
                                ></i>
                                <div class="media-body media-body-custom">
                                  <p
                                    class="d-inline-block"
                                    style="
                                      font-size: 20px;
                                      margin-left: 30px;
                                      margin-top: 40px;
                                      margin-bottom: 70px;
                                    "
                                  >
                                    {{ $t("views.payouts.upload_payout") }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="p-3">
                            <form
                              id="modal"
                              action="#"
                              @submit.prevent="fileUploads"
                            >
                              <div class="row">
                                <div
                                  class="col-lg-12 position-relative float-right"
                                >
                                  <select
                                    name="ChannelKey"
                                    class="form-control uploads2"
                                    style="width: 100%"
                                    v-model="selectedChannel"
                                  >
                                    <option value="" selected>
                                      {{ $t("views.payouts.select_psp") }}
                                    </option>
                                    <option
                                      :key="option.key"
                                      v-for="option in paymentChannelCredsList"
                                      :value="option"
                                    >
                                      {{ option }}
                                    </option>
                                  </select>

                                  <div
                                    class="text-danger mt-2"
                                    style="font-size: 80%;"
                                    v-if="selectedChannel === 'PAYNAMICS_PAYOUT'"
                                  >
                                    Bulk Payout creation is currently not available for Paynamics Payout channel
                                  </div>
                                  <div
                                    class="text-danger mt-2"
                                    style="font-size: 80%;"
                                    v-if="paymentChannelCredsList.length == 0"
                                  >
                                    Please configure a Payout Channel at
                                    <a href="/settings/payout-channel"
                                      >Payout Channels section</a
                                    >
                                    or Kindly contact business team of PortOne
                                    for more details.
                                  </div>
                                </div>
                              </div>

                              <div class="row pb-3">
                                <div
                                  class="col-lg-12 position-relative float-right"
                                >
                                  <input
                                    type="file"
                                    multiple
                                    name="UploadFiles"
                                    ref="fileupload"
                                    class="file_Upload input-file"
                                    :disabled="selectedChannel === 'PAYNAMICS_PAYOUT'"
                                    @change="
                                      fileUpload(
                                        $event.target.name,
                                        $event.target.files
                                      )
                                    "
                                  />
                                  <div class="bulk-upload-button btn-sm">
                                    <img
                                      style="
                                        position: absolute;
                                        left: 25px;
                                        top: 50px;
                                      "
                                      src=""
                                      class="cursor-pointer"
                                      alt
                                    />
                                    Upload Files Here
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="!fileUploadSuccess"
                                class="media-body-custom pt-3"
                                style="border-top: 1px solid #d9d9d9"
                              >
                                <img src="@/assets/images/arrow.png" alt />{{
                                  $t("common.0_file_uploaded")
                                }}
                              </div>
                              <div
                                v-if="fileUploadSuccess"
                                class="media-body-custom pt-3"
                                style="border-top: 1px solid #d9d9d9"
                              >
                                <img src="@/assets/images/tick.png" alt />{{
                                  $t("common.1_file_uploaded")
                                }}
                              </div>
                              <div
                                v-if="!selectedChannel"
                                style="color: red; margin-top: 10px"
                              >
                                {{ $t("common.select_channel") }}
                              </div>

                              <b-form-checkbox
                                class="my-3"
                                v-if="selectedChannel == 'GBPRIMEPAY_PAYOUT'"
                                v-model="split_payouts"
                                :value="true"
                                :unchecked-value="false"
                              >
                                Automatically split payouts with amount greater than THB 700,000
                              </b-form-checkbox>

                              <div class="text-left" style="margin-top: 60px">
                                <button
                                  type="submit"
                                  class="btn btn-primary dark-bg ml-1 col-4"
                                  :disabled="
                                    !fileUploadSuccess || !selectedChannel || selectedChannel === 'PAYNAMICS_PAYOUT'
                                  "
                                >
                                  {{ $t("button.submit") }}
                                </button>
                                <b-button
                                  class="ml-2 col-4"
                                  variant="light"
                                  @click="hideUploadModal"
                                  >{{ $t("button.cancel") }}</b-button
                                >
                              </div>
                            </form>
                          </div>
                        </div>
                        <!-- end slimscroll-menu-->
                      </div>
                      <!-- /Right-bar -->
                      <!-- Right bar overlay-->
                      <div
                        @click="hideUploadModal"
                        class="rightbar-overlay"
                        :class="{
                          active: showUploadmodal,
                        }"
                      ></div>
                    </div>

                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-4">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab
                v-if="
                  isVerifyPayoutsEnabledData.isVerifyPayoutsEnabled &&
                  !isMasterMerchant
                "
                @click="getNotVerifiedData()"
                title-link-class="p-3"
              >
                <template v-slot:title>
                  {{ $t("views.payouts.verif-title") }}
                </template>
                <div v-if="currentPageTitle == 'not-verified'">
                  <b-alert
                    variant="danger"
                    class="text-center mt-2"
                    :show="!$store.state.auth.currentUser.is_2fa_enabled"
                  >
                    {{ $t("views.payouts.2fa_message") }} <router-link to="/profile">{{ $t("views.payouts.click_here") }}</router-link> {{ $t("views.payouts.2fa_message_end") }}
                  </b-alert>
                  <div class="my-4">
                    <b-alert
                      variant="warning"
                      class="text-center mx-auto"
                      :show="selectedTransactionRefs.length > 0"
                    >
                      {{selectAllTransactions ? `All ${payoutTotalElementsFromStore}` : selectedTransactionRefs.length }} Transactions are selected.
                      <b-btn variant="link" size="sm" v-if="!selectAllTransactions" class="p-0 text-info" @click="selectAllTxn">select all {{ payoutTotalElementsFromStore }} Transactions.</b-btn>
                    </b-alert>
                    <div class="d-flex justify-content-between">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          {{ $t("common.show") }}&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="refreshRecord"
                          ></b-form-select
                          >&nbsp;{{ $t("common.entries") }}
                        </label>
                      </div>
                      <div class="w-50">
                        <b-input-group>
                          <b-form-input
                            type="search"
                            v-model="mor_filter"
                            @keyup="refreshRecord"
                            @keyup.enter="refreshRecord"
                            class="form-control-sm"
                            placeholder="Search for references or customer details"
                          ></b-form-input>
                          <b-input-group-append>
                            <button
                              class="btn btn-primary btn-block btn-sm"
                              @click="refreshRecord"
                            >
                              {{ $t("button.search") }}
                            </button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <b-table
                      class="table-centered text-center table-custom"
                      :items="payoutsDataFromStore"
                      :fields="fields"
                      responsive="lg"
                      :bordered="false"
                      sticky-header="500px"
                      show-empty
                      :empty-text="$t('common.no-records')"
                      busy.sync="true"
                      hover
                    >
                      <template v-slot:cell(selectpayout)="row">
                        <b-form-checkbox
                          :id="`customercheck${row.item.txn_ref}`"
                          :name="`customercheck${row.item.txn_ref}`"
                          :disabled="
                            row.item.verified_status === 'Verified' ||
                            row.item.status != 'Created'
                          "
                          :checked="selectedTransactionRefs.includes(row.item.txn_ref)"
                          @change="toggleSelectPayout(row.item.txn_ref)"
                        ></b-form-checkbox>
                      </template>

                      <template v-slot:cell(amount)="row">
                        <div>
                          <span class="align-middle">{{
                            row.value.toLocaleString("en-US", {
                              style: "currency",
                              currency: row.item.currency,
                            })
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(email_notification_sent)="row">
                        <img src="@/assets/images/check-circle.svg" v-if="row.value">
                        <img src="@/assets/images/x-circle.svg" v-else>
                      </template>

                      <template v-slot:cell(sms_notification_sent)="row">
                        <img src="@/assets/images/check-circle.svg" v-if="row.value">
                        <img src="@/assets/images/x-circle.svg" v-else>
                      </template>

                      <template v-slot:cell(due_date)="row">
                        <div>
                          <span class="align-middle">{{
                            getFormattedDate(row.value)
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(approved_time)="row">
                        <div>
                          <span class="align-middle">{{
                            getFormattedDate(row.value)
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(email_sent)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Sent',
                            'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                            'badge-soft-warning': row.value === 'Waiting',
                            'badge-soft-secondary':
                              row.value === '' ||
                              row.value === 'N/A',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(sms_sent)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Sent',
                            'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                            'badge-soft-warning': row.value === 'Waiting',
                            'badge-soft-secondary':
                              row.value === '' ||
                              row.value === 'N/A',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(approved_status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Approved',
                            'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                            'badge-soft-warning': row.value === 'Pending',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(verified_status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Verified',
                            'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                            'badge-soft-warning': row.value === 'Pending',
                            'badge-soft-secondary': `${row.value}` === '',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>
                      <template v-slot:cell(status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': `${row.value}` === 'Success',
                            'badge-soft-info': `${row.value}` === 'Created',
                            'badge-soft-warning':
                              `${row.value}` === 'Initiated' ||
                              `${row.value}` === 'Pending',
                            'badge-soft-secondary':
                              `${row.value}` === 'Pending',
                            'badge-soft-danger':
                              `${row.value}` === 'Failed' ||
                              `${row.value}` === 'Cancelled' ||
                              `${row.value}` === 'Rejected',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:head()="data">
                        <b-form-checkbox
                          v-if="data.column === 'selectpayout'"
                          v-model="selectAvailableInPage"
                          id="select_all"
                          name="select_all"
                          :value="true"
                          :unchecked-value="false"
                          @change="selectAllInPage"
                        ></b-form-checkbox>
                        <span
                          v-else
                          v-b-tooltip.hover.top
                          :title="$t(data.field.tooltip)"
                        >
                          {{ $t(data.label) }}
                        </span>
                      </template>
                    </b-table>
                    <Spinner v-if="isFetchingTransactions === true"></Spinner>
                  </div>
                  <div class="row">
                    <div class="col">
                      <button
                        v-if="userPermissions.includes('verify-payouts')"
                        class="btn btn-primary btn"
                        @click="verifyModal('approve')"
                        :disabled="selectedTransactionRefs.length === 0"
                        style="padding: 0.3rem 1.5rem"
                      >
                        {{ $t("views.payouts.verify") }}
                      </button>
                      <button
                        v-if="userPermissions.includes('verify-payouts')"
                        class="btn btn-danger ml-2"
                        @click="verifyModal('reject')"
                        :disabled="selectedTransactionRefs.length === 0"
                        style="padding: 0.3rem 1.5rem"
                      >
                        Reject
                      </button>
                    </div>
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-4">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab
                @click="getNotApprovedData()"
                v-if="!isMasterMerchant"
                title-link-class="p-3"
              >
                <template v-slot:title>
                  {{ $t("views.payouts.approve-title") }}
                </template>
                <div v-if="currentPageTitle == 'not-approved'">
                  <b-alert
                    variant="danger"
                    class="text-center mt-2"
                    :show="!$store.state.auth.currentUser.is_2fa_enabled"
                  >
                    Two-factor (2FA) Authentication is required to approve Payouts. <router-link to="/profile">Click here</router-link> to Enable 2FA for your profile.
                  </b-alert>
                  <div class="my-4">
                    <b-alert
                      variant="warning"
                      class="text-center"
                      :show="selectedTransactionRefs.length > 0"
                    >
                      {{selectAllTransactions ? `All ${payoutTotalElementsFromStore}` : selectedTransactionRefs.length }} Transactions are selected.
                      <b-btn variant="link" size="sm" v-if="!selectAllTransactions" class="p-0 text-info" @click="selectAllTxn">select all {{ payoutTotalElementsFromStore }} Transactions.</b-btn>
                    </b-alert>
                    <div class="d-flex justify-content-between">
                      <div id="tickets-table_length" class="dataTables_length d-flex">
                        <label class="d-inline-flex align-items-center">
                          <span class="text-nowrap">{{
                            $t("common.show")
                          }}</span
                          >&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="refreshRecord"
                          ></b-form-select
                          >&nbsp;<span class="text-nowrap">{{
                            $t("common.entries")
                          }}</span>
                        </label>
                      </div>
                      <div class="w-50">
                        <b-input-group>
                          <b-form-input
                            type="search"
                            v-model="mor_filter"
                            @keyup="refreshRecord"
                            @keyup.enter="refreshRecord"
                            class="form-control-sm"
                            placeholder="Search for references or customer details"
                          ></b-form-input>
                          <b-input-group-append>
                            <button
                              class="btn btn-primary btn-block btn-sm"
                              @click="refreshRecord"
                            >
                              {{ $t("button.search") }}
                            </button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <b-table
                      class="table-centered text-center table-custom"
                      :items="payoutsDataFromStore"
                      :fields="fields"
                      responsive="lg"
                      :bordered="false"
                      sticky-header="500px"
                      show-empty
                      :empty-text="$t('common.no-records')"
                      busy.sync="true"
                      hover
                    >
                      <template v-slot:cell(selectpayout)="row">
                        <b-form-checkbox
                          :id="`customercheck${row.item.txn_ref}`"
                          :name="`customercheck${row.item.txn_ref}`"
                          :disabled="
                            row.item.approved_status === 'Approved' ||
                            row.item.status != 'Created'
                          "
                          :checked="selectedTransactionRefs.includes(row.item.txn_ref)"
                          @change="toggleSelectPayout(row.item.txn_ref)"
                        ></b-form-checkbox>
                      </template>

                      <template v-slot:cell(amount)="row">
                        <div>
                          <span class="align-middle">{{
                            row.value.toLocaleString("en-US", {
                              style: "currency",
                              currency: row.item.currency,
                            })
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(email_notification_sent)="row">
                        <img src="@/assets/images/check-circle.svg" v-if="row.value">
                        <img src="@/assets/images/x-circle.svg" v-else>
                      </template>

                      <template v-slot:cell(sms_notification_sent)="row">
                        <img src="@/assets/images/check-circle.svg" v-if="row.value">
                        <img src="@/assets/images/x-circle.svg" v-else>
                      </template>

                      <template v-slot:cell(due_date)="row">
                        <div>
                          <span class="align-middle">{{
                            getFormattedDate(row.value)
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(approved_time)="row">
                        <div>
                          <span class="align-middle">{{
                            getFormattedDate(row.value)
                          }}</span>
                        </div>
                      </template>

                      <template v-slot:cell(email_sent)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Sent',
                            'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                            'badge-soft-warning': row.value === 'Waiting',
                            'badge-soft-secondary':
                              row.value === '' ||
                              row.value === 'N/A',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(sms_sent)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Sent',
                            'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                            'badge-soft-warning': row.value === 'Waiting',
                            'badge-soft-secondary':
                              row.value === '' ||
                              row.value === 'N/A',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(approved_status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Approved',
                            'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                            'badge-soft-warning': row.value === 'Pending',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:cell(verified_status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': row.value === 'Verified',
                            'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                            'badge-soft-warning': row.value === 'Pending',
                            'badge-soft-secondary': `${row.value}` === '',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>
                      <template v-slot:cell(status)="row">
                        <div
                          class="badge font-size-12"
                          :class="{
                            'badge-soft-success': `${row.value}` === 'Success',
                            'badge-soft-info': `${row.value}` === 'Created',
                            'badge-soft-warning':
                              `${row.value}` === 'Initiated' ||
                              `${row.value}` === 'Pending',
                            'badge-soft-secondary':
                              `${row.value}` === 'Pending',
                            'badge-soft-danger':
                              `${row.value}` === 'Failed' ||
                              `${row.value}` === 'Cancelled' ||
                              `${row.value}` === 'Rejected',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>

                      <template v-slot:head()="data">
                        <b-form-checkbox
                          v-if="data.column === 'selectpayout'"
                          v-model="selectAvailableInPage"
                          id="select_all"
                          name="select_all"
                          :value="true"
                          :unchecked-value="false"
                          @change="selectAllInPage"
                        ></b-form-checkbox>
                        <span
                          v-else
                          v-b-tooltip.hover.top
                          :title="$t(data.field.tooltip)"
                        >
                          {{ $t(data.label) }}
                        </span>
                      </template>
                    </b-table>
                    <Spinner v-if="isFetchingTransactions === true"></Spinner>
                  </div>
                  <div class="row">
                    <div class="col">
                      <button
                        v-if="userPermissions.includes('approve-payouts')"
                        class="btn btn-primary btn-sm"
                        @click="approveModal('approve')"
                        :disabled="selectedTransactionRefs.length === 0"
                        style="padding: 0.3rem 1.5rem"
                      >
                        {{ $t("views.payouts.approve") }}
                      </button>
                      <button
                        v-if="userPermissions.includes('approve-payouts')"
                        class="btn btn-danger btn-sm ml-2"
                        @click="approveModal('reject')"
                        :disabled="selectedTransactionRefs.length === 0"
                        style="padding: 0.3rem 1.5rem"
                      >
                        {{ $t("views.payouts.reject") }}
                      </button>
                    </div>
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-4">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Payouts modal -->
    <div>
      <PayoutForm v-if="showmodal" :showmodal="showmodal" :editItem="editItem" :isEdit="isEdit" />
    </div>

    <div>
      <b-modal
        id="modalmobile"
        centered
        v-model="enterMobile"
        title="Enter Mobile Number"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <form id="modalmobile" @submit.prevent="GenerateOtp">
          <div class="form-group">
            <label for="Mobile">{{ $t("views.payouts.mobile") }}</label>
            <input
              type="tel"
              name="mobile"
              v-model="payoutMobile.mobileNo"
              class="form-control"
              :class="{
                'is-invalid': submitted && $v.payoutMobile.mobileNo.$error,
              }"
            />
            <div
              v-if="submitted && !$v.payoutMobile.mobileNo.required"
              class="invalid-feedback"
            >
              {{ $t("views.payouts.required_field_msg.mobile") }}
            </div>
          </div>

          <div class="text-right">
            <b-button class="mr-1" variant="light" @click="hideMobile">
              {{ $t("button.close") }}
            </b-button>

            <button type="submit" class="btn btn-success ml-1">
              {{ $t("button.submit") }}
            </button>
          </div>
        </form>
      </b-modal>
    </div>

    <!-- OTP input for approval -->
    <div>
      <b-modal
        id="modal"
        centered
        v-model="showApprove"
        :title="$t('views.payouts.otp_verification')"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <form id="modal" @submit.prevent="ApprovePayments">
          <span> {{ $t("views.payouts.otp_msg") }} </span>
          <div class="mt-2 form-group">
            <label for="OTP">{{ $t("views.payouts.enter_otp") }}</label>
            <OtpInput
              name="otp"
              :input-classes="
                submitted && $v.payoutApprove.otp.$error
                  ? 'is-invalid otp-input form-control'
                  : 'otp-input form-control'
              "
              separator="-"
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOtpChange"
            />
            <div
              v-if="submitted && !$v.payoutApprove.otp.required"
              class="invalid-feedback"
            >
              {{ $t("views.payouts.required_field_msg.otp") }}
            </div>
            <div class="mt-2">
              <a
                style="text-decoration: underline"
                href="javascript:void(0);"
                @click="resendOTP"
                >{{ $t("views.payouts.resend_otp") }}</a
              >
            </div>
            <b-form-checkbox
              v-model="payoutApprove.saveOtp"
              :value="true"
              :unchecked-value="false"
              class="mt-2"
            >
              Do not ask for authentication for the next 10 minutes
            </b-form-checkbox>
          </div>

          <div class="text-right">
            <b-button class="mr-1" variant="light" @click="hideApprove">
              {{ $t("button.close") }}
            </b-button>

            <button type="submit" class="btn btn-primary ml-1">
              {{ $t("button.submit") }}
            </button>
          </div>
        </form>
      </b-modal>
    </div>

    <!-- OTP input for verify -->
    <div>
      <b-modal
        id="modal"
        centered
        v-model="showVerify"
        :title="$t('views.payouts.otp_verification')"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <form id="modal" @submit.prevent="VerifyPayments">
          <span> {{ $t("views.payouts.otp_msg") }} </span>
          <div class="mt-2 form-group">
            <label for="OTP">{{ $t("views.payouts.enter_otp") }}</label>
            <OtpInput
              name="otp"
              :input-classes="
                submitted && $v.payoutVerify.otp.$error
                  ? 'is-invalid otp-input form-control'
                  : 'otp-input form-control'
              "
              separator="-"
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              v-model="payoutVerify.otp"
              @on-change="handlePayoutOtpChange"
            />
            <div
              v-if="submitted && !$v.payoutVerify.otp.required"
              class="invalid-feedback"
            >
              {{ $t("views.payouts.required_field_msg.otp") }}
            </div>
            <div class="mt-2">
              <a
                style="text-decoration: underline"
                href="javascript:void(0);"
                @click="resendOTPForVerify"
                >{{ $t("views.payouts.resend_otp") }}</a
              >
            </div>
            <b-form-checkbox
              v-model="payoutVerify.saveOtp"
              :value="true"
              :unchecked-value="false"
              class="mt-2"
            >
              Do not ask for authentication for the next 10 minutes
            </b-form-checkbox>
          </div>

          <div class="text-right">
            <b-button class="mr-1" variant="light" @click="hideVerify">
              {{ $t("button.close") }}
            </b-button>

            <button type="submit" class="btn btn-primary ml-1">
              {{ $t("button.submit") }}
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </Layout>
</template>

<style>
.swal2-container {
  z-index: 9999;
}
</style>

<style lang="css" scoped>
::v-deep .b-table-sticky-column:last-child {
  right: 0;
}
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep #payouts-filter-collapse > .multiselect__tag {
  background-color: #41b883;
}
.file_Upload {
  opacity: 0;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0px;
  width: 140px;
  z-index: 3000;
  cursor: pointer;
}
.bulk-upload-button {
  text-align: center;
  color: #b3afaf;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  padding: 15px 10px;
  border: 1px dashed #cbcbcb;
  margin-top: 40px;
}
.right-top-section {
  /* border-bottom: 1px solid #D9D9D9; */
  background-color: #f7f7f7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  position: relative;
  z-index: 1;
}
.right-bar {
  width: 600px;
  right: -600px;
}
.right-bar.active {
  right: 0px;
}
.rightbar-overlay.active {
  display: block;
  position: fixed;
}
.rightbar-title {
  color: #252b3b;
}
.cursor-pointer {
  cursor: pointer;
}
.right-bar .form-control,
.right-bar .custom-multiselect .multiselect__tags {
  border: 0.5px solid #00246414;
}
.right-top-bg-image {
  position: absolute;
  right: 0px;
}
.right-bottom-bg-image {
  position: absolute;
  bottom: 0px;
}
.avatar-custom {
  height: 70px;
  width: 95px;
}
@media (max-width: 700px) {
  .right-bar {
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar {
    width: 320px;
    right: -320px;
  }
}
.file_Upload {
  opacity: 0;
  height: 50px;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.uploads {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploads1 {
  margin-top: 20%;
  display: flex;
  padding: 0 20px;
}
.uploads2 {
  width: 200px;
}
::v-deep .custom-datepicker .xmx-input{
  height: 35px !important;
  padding-top: 4px;
  min-height: unset;
}
@media (max-width: 1300px) {
  .right-bar {
    width: 360px;
    right: -360px;
  }
}
@media (max-width: 400px) {
  .right-bar {
    width: 320px;
    right: -320px;
  }
}

.down-btn button{
  height: 31px;
  position: relative;
  margin-left: 10px;
}
::v-deep .btn{
  border-radius: 4px;
}
::v-deep .form-control{
  font-size: 14px;
}
::v-deep label{
  font-size: 14px;
}
.payout-form-top .btn{
  border-radius: 4px;
}
</style>
